<template>
    <div>
        <div class="typeContainer">
            <div style="width: 100%; display: flex; flex-flow: wrap">
                <template v-if="devicesList && devicesList.length > 0">
                    <div :class="['typeItem', index === currentIndex ? 'active' : '']" v-for="(item, index) in devicesList" :key="item.id" @click="changeDevice(item, index)">
                        <div class="typeItem-title">
                            <span>{{ item.deviceName }}{{ item.deviceModel }}</span>
                        </div>
                        <div class="small-title">绑定数量</div>
                        <div class="num num_Din">
                            {{ item.bingCount }}
                        </div>
                    </div>
                </template>
                <div v-else class="myTip">暂无设备，请先进行设备添加~</div>
            </div>
        </div>
        <el-card class="box-card" shadow="never">
            <div slot="header" class="myHeader">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="所属组织">
                        <el-cascader
                            clearable
                            placeholder="请选择所属组织"
                            v-model="streetArr"
                            :props="defaultProps"
                            class="w300"
                            ref="supervisedUnitCode"
                            :show-all-levels="false"
                            filterable
                            :options="streetList"
                        ></el-cascader>
                    </el-form-item>
                    <el-form-item label="设备名称">
                        <el-select v-model="deviceName" placeholder="请选择设备名称" clearable class="w300" @change="changName">
                            <el-option v-for="item in devicesList" :key="item.id" :label="item.deviceName" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="关键字">
                        <el-input v-model="keyWord" placeholder="请输入绑定人姓名/设备识别号" clearable class="w300" />
                    </el-form-item>
                    <el-form-item label="时间范围">
                        <el-date-picker
                            v-model="timeRange"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            clearable
                            class="w300"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="doSelect">搜索</el-button>
                    </el-form-item>
                </el-form>
                <div>
                    <el-button type="primary" plain @click="downLoadTable">导出</el-button>
                </div>
            </div>
            <div class="table-content">
                <el-table v-loading="loading" :data="tableData" @sort-change="sortChange" border :header-cell-style="headerCellStyle">
                    <template slot="empty">
                        <IsEmpty />
                    </template>
                    <el-table-column prop="deviceSn" label="设备唯一识别编码" align="center" />
                    <el-table-column prop="deviceName" label="设备名称" align="center" />
                    <el-table-column prop="userName" label="绑定人" align="center" />
                    <el-table-column prop="tenantName" label="所属组织" align="center">
                        <template slot-scope="scope">
                            <span>{{
                                scope.row.tenantName && scope.row.communityName
                                    ? `${scope.row.tenantName}/${scope.row.communityName}`
                                    : scope.row.tenantName
                                    ? scope.row.tenantName
                                    : ''
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="电话" align="center" />
                    <el-table-column prop="bindTime" label="绑定日期" sortable="custom" align="center" />
                </el-table>
                <el-pagination
                    style="margin-top: 20px"
                    background
                    layout="total, sizes, prev, pager, next"
                    :total="total"
                    :current-page="pageNum"
                    :page-size="pageSize"
                    :page-sizes="[10, 20, 30, 40]"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </el-card>
    </div>
</template>

<script>
import { getDevicesList, getDevicesBindingList, downBindExcel } from '@/api/homeSave'
import { downloadStream } from '@/utils/index'
import { streetList } from '@/api/orderManage'
export default {
    // 健康设备管理
    name: 'healthDevicesManager',
    data() {
        return {
            loading: false,
            pageNum: 1,
            pageSize: 10,
            total: 0,
            tableData: [], // 表格数据
            devicesList: [], // 设备列表
            currentIndex: 0, // 当前设备索引值
            currentDevice: {}, // 当前设备
            orderParams: {}, // 排序条件
            keyWord: '', // 搜索关键字
            sn: '',
            deviceName: '',
            streetArr: '',
            defaultProps: {
                value: 'streetId',
                label: 'streetName',
                children: 'streetNameListResultList',
                checkStrictly: true
            },
            streetList: [],
            timeRange: ''
        }
    },
    mounted() {
        this.getDevicesList()
        this.getStreetList()
    },
    methods: {
        // 获取组织列表
        getStreetList() {
            streetList().then(res => {
                res.data.forEach(item => {
                    item.streetNameListResultList.forEach(itemC => {
                        if (itemC.streetNameListResultList.length === 0) {
                            delete itemC.streetNameListResultList
                        }
                    })
                })
                this.streetList = res.data
            })
        },
        // 翻页
        handleCurrentChange(val) {
            this.pageNum = val
            this.getList(this.currentDevice, this.orderParams)
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.getList(this.currentDevice, this.orderParams)
        },
        // 搜索
        doSelect() {
            this.pageNum = 1
            this.getList(this.currentDevice, this.orderParams)
        },
        // 更改排序
        sortChange(changItem) {
            if (changItem.order) {
                this.orderParams = {
                    sort: changItem.order === 'ascending' ? 0 : 1
                }
            } else {
                this.orderParams = {}
            }
            this.getList(this.currentDevice, this.orderParams)
        },
        // 获取设备列表
        getDevicesList() {
            getDevicesList().then(res => {
                this.devicesList = res.data
                if (this.devicesList.length > 0) {
                    this.currentDevice = res.data[0]
                    this.deviceType = this.currentDevice.deviceType
                    this.getList(this.currentDevice, this.orderParams)
                }
            })
        },
        // 某设备下的绑定列表
        getList(item, orderParams) {
            this.loading = true
            const params = {
                mdId: item.id,
                keyWord: this.keyWord,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                startBindDate: this.timeRange ? this.timeRange[0] : '',
                endBindDate: this.timeRange ? this.timeRange[1] : '',
                tenantId: this.streetArr.length > 0 ? this.streetArr[0] : '',
                orgId: this.streetArr.length > 1 ? this.streetArr[1] : ''
            }
            let allParams = orderParams ? Object.assign({ ...params, ...orderParams }) : params
            getDevicesBindingList(allParams)
                .then(res => {
                    this.tableData = res.data.rows
                    this.total = res.data.total
                    this.loading = false
                })
                .catch(() => {
                    this.tableData = []
                    this.total = 0
                    this.loading = false
                })
        },
        changName(item) {
            const index = this.devicesList.findIndex(val => {
                return val.id === item
            })
            const currentIndex = index === -1 ? 0 : index
            this.changeDevice(this.devicesList[currentIndex], currentIndex)
        },
        // 更改设备
        changeDevice(item, index) {
            this.currentIndex = index
            this.currentDevice = item
            this.pageNum = 1
            this.getList(item)
        },
        // 下载表格数据
        async downLoadTable() {
            const params = {
                mdId: this.currentDevice.id,
                keyWord: this.keyWord,
                startBindDate: this.timeRange ? this.timeRange[0] : '',
                endBindDate: this.timeRange ? this.timeRange[1] : '',
                tenantId: this.streetArr.length > 0 ? this.streetArr[0] : '',
                orgId: this.streetArr.length > 1 ? this.streetArr[1] : ''
            }
            let allParams = this.orderParams ? Object.assign({ ...params, ...this.orderParams }) : params
            const res = await downBindExcel(allParams)
            downloadStream(res, '健康设备信息.xls')
        }
    }
}
</script>

<style lang="scss" scoped>
.typeContainer {
    background: #fff;
    width: 100%;
    padding: 15px 18px;
    .myTip {
        font-size: 16px;
        font-weight: 400;
        color: #ababab;
        margin: 60px 37px;
    }
    .active {
        border-color: #3fcf89 !important;
        // box-shadow: 0px 2px 4px 0px rgba(5, 190, 0, 0.3) !important;
    }
    .typeItem {
        width: 240px;
        height: 150px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #e0e0e0;
        margin-bottom: 15px;
        margin-right: 30px;
        cursor: pointer;
        &-title {
            padding: 9px 0;
            background: #3fcf89;
            border-radius: 4px 4px 0px 0px;
            color: #fff;
            text-align: center;
            font-size: 14px;
        }
        .small-title {
            font-size: 18px;
            color: #999;
            text-align: center;
            padding: 13px 0;
        }
        .num {
            text-align: center;
            font-size: 40px;
            font-weight: bold;
            color: #333333;
        }
    }
}
.box-card {
    margin-top: 12px;
    .myHeader {
        display: flex;
        justify-content: space-between;
    }
}

/deep/ .el-card {
    border: none;
}
</style>
